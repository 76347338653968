nextgen-form-renderer .sd-root-modern__wrapper {
  --primary: var(--Spa_Style_Primary_Link);
  --spa-input-border-color: #949494;
  --spa-disabled-input-border-color: #cccccc;
  --spa-disabled-input-background: #ededed;
  --spa-disabled-input-color: #666666;
  --spa-fontSize-small: 14px;
  --spa-fontSize-larger: 22px;
  --spa-heading-font-family: 'montserrat_light', sans-serif;
  --sjs-article-font-x-large-fontSize: 32px;
  --spa-white: rgba(255, 255, 255, 1);
  --sjs-primary-backcolor: var(--Spa_Style_Primary_Link);
  --sjs-font-family: 'montserratregular', sans-serif;
  --sjs-font-questiontitle-size: var(--spa-fontSize-small);
  --sjs-font-size: var(--spa-fontSize-small);
  --sjs-font-questiontitle-weight: 400;
  --sjs-font-questiontitle-family: 'montserrat_bold', sans-serif;
  --sjs-article-font-large-fontSize: var(--spa-fontSize-larger);
  --sjs-article-font-large-fontWeight: 500;
  --sjs-base-unit: 8px;
  --sjs-corner-radius: 5px;
  --sjs-special-red: var(--static-Spa_Style_Accent2, var(--red, #e60a3e));
  --sjs-font-pagedescription-size: var(--spa-fontSize-small);
  --sjs-font-pagetitle-size: var(--spa-fontSize-larger);
  --sjs-font-questiontitle-size: var(--spa-fontSize-small);
  --sjs-font-questiondescription-size: var(--spa-fontSize-small);
  --sjs-font-editorfont-placeholdercolor: var(--Spa_Style_Gray4);
  --sjs-font-editorfont-size: var(--spa-fontSize-small);
  --sjs-font-editorfont-color: var(--Spa_Style_Primary);
  --sjs-font-surveydescription-size: var(--spa-fontSize-small);
  --sjs-font-surveytitle-size: var(--spa-fontSize-larger);
  --sjs-editor-background: var(--static-Spa_Style_Gray1);
  --sjs-editorpanel-hovercolor: var(--Spa_Style_Gray1);
  --sjs-font-questiondescription-color: var(--Spa_Style_Gray4);
  --sjs-font-questiontitle-color: var(--Spa_Style_Primary);
  --sjs-font-pagedescription-color: var(--Spa_Style_Gray4);
  --sjs-font-pagetitle-color: var(--Spa_Style_Primary);
  --sjs-question-background: var(--spa-white);
  --sjs-questionpanel-hovercolor: var(--spa-white);
  --sjs-border-default: rgba(0, 0, 0, 0.42);
}

:root {
  --sat-tooltip-arrow-left-offset: 0px;
  --sat-tooltip-down-pointing-arrow: '\25BC';
  --sat-tooltip-up-pointing-arrow: '\25B2';
}

@media (min-width: 992px) {
  vnext-zone-content-left.col-md-9.col-lg-8:has(nextgen-form-renderer) {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

nextgen-form-renderer .sd-root-modern {
  --sd-base-padding: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  --sd-base-vertical-padding: calc(3 * var(--sjs-base-unit, var(--base-unit, 8px)));
  overflow-x: hidden !important;
}

nextgen-form-renderer .sd-root-modern {
  background: none;
}

nextgen-form-renderer .sd-body.sd-body--responsive,
nextgen-form-renderer .sd-root-modern--mobile .sd-body.sd-body--responsive {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0 !important;
}

nextgen-form-renderer .sd-element--nested-with-borders {
  padding-left: 0 !important;
  padding-right: 0;
  border: none;
}

nextgen-form-renderer .sd-element--nested-with-borders .sd-panel__content {
  padding-top: 0;
  padding-bottom: 0 !important;
}

nextgen-form-renderer .edit-link-element {
  font-size: var(--Spa_Style_Small_Font);
  color: var(--Spa_Style_Primary_Link) !important;
}

nextgen-form-renderer .edit-link-element:hover {
  color: var(--Spa_Style_Primary_Link_Hover) !important;
}

nextgen-form-renderer h1,
nextgen-form-renderer .sd-html h1 {
  font-size: var(--sjs-article-font-x-large-fontSize);
}

nextgen-form-renderer h1,
nextgen-form-renderer .sd-html h1 nextgen-form-renderer h2,
nextgen-form-renderer .sd-html h2 {
  font-family: var(--spa-heading-font-family);
  font-weight: 400;
}

nextgen-form-renderer h3,
nextgen-form-renderer .sd-html h3,
nextgen-form-renderer .nextgen-sepreasons-title span {
  font-family: var(--spa-heading-font-family);
  font-size: var(--sjs-article-font-large-fontSize, calc(2 * (var(--sjs-font-size, 16px))));
  font-weight: var(--sjs-article-font-large-fontWeight, 500);
  margin-top: 0.5rem;
  margin-bottom: 0;
}

nextgen-form-renderer .nextgen-sepreasons-title span {
  font-style: var(--sjs-article-font-large-fontStyle, 'normal');
  font-stretch: var(--sjs-article-font-large-fontStretch, 'normal');
  letter-spacing: var(--sjs-article-font-large-letterSpacing, 0);
  line-height: var(--sjs-article-font-large-lineHeight, 40px);
  text-indent: var(--sjs-article-font-large-paragraphIndent, 0px);
  text-transform: var(--sjs-article-font-large-textCase, 'none');
}

nextgen-form-renderer p,
nextgen-form-renderer .sd-html p,
nextgen-form-renderer .nextgen-sepreasons-text-container {
  font-family: var(--sjs-font-family);
  font-size: var(--sjs-font-size);
}

nextgen-form-renderer .sd-element--with-frame {
  background: none;
  box-shadow: none;
  padding: 0;
}

nextgen-form-renderer .sd-container-modern__title.sd-title {
  display: none;
}

nextgen-form-renderer .sd-boolean {
  background-color: var(--static-Spa_Style_Gray2);
  border: 1px solid var(--spa-input-border-color);
}

nextgen-form-renderer .sd-boolean.sd-boolean--allowhover:focus-within {
  box-shadow: var(--sjs-shadow-inner-reset, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)), 0 0 5px var(--static-Spa_Style_Black) !important;
}

nextgen-form-renderer .sd-boolean__thumb {
  background-color: var(--static-Spa_Style_Toggle);
  border: 2px solid var(--static-Spa_Style_Primary_Link);
  color: var(--static-Spa_Style_Primary_Link);
  transition: none !important;
}

nextgen-form-renderer .sd-boolean--disabled .sd-boolean__thumb,
nextgen-form-renderer .sd-boolean--disabled .sd-boolean__thumb-ghost span {
  color: var(--static-Spa_Style_Primary_Link) !important;
}

nextgen-form-renderer textarea.sd-input {
  background: var(--spa-white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--spa-input-border-color);
  border-radius: var(--sjs-corner-radius);
}

nextgen-form-renderer .sd-dropdown {
  border-width: 1px;
  border-style: solid;
  border-color: var(--spa-input-border-color);
  height: var(--input-height);
  padding: 0px; /* different from PC styles */
  padding-right: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: var(--sjs-corner-radius);
  background-color: #0000;
  background-image: linear-gradient(var(--static-Spa_Style_Gray2) 0%, var(--static-Spa_Style_Gray2) 0%, white 25%, white 100%);
}

nextgen-form-renderer .sd-dropdown input {
  border-width: 0px;
}

nextgen-form-renderer .sd-dropdown .sv-string-viewer,
nextgen-form-renderer .sd-dropdown .sd-dropdown__hint-suffix > span:first-child {
  z-index: 999;
  position: relative;
  line-height: 34px;
  margin-left: 11px;
  font-size: var(--spa-fontSize-small);
}

nextgen-form-renderer .sd-dropdown--empty > div {
  line-height: 34px;
  margin-left: 11px;
}

nextgen-form-renderer input[type='text'],
nextgen-form-renderer input[type='password'] {
  border-color: var(--spa-input-border-color);
}

nextgen-form-renderer input[type='number'],
nextgen-form-renderer input[type='email'],
nextgen-form-renderer .sd-input[type='date'] {
  border-width: 1px;
  border-style: solid;
  border-color: var(--spa-input-border-color);
  height: var(--input-height);
  padding: 5px 11px;
  border-radius: var(--sjs-corner-radius);
  background-color: #0000;
  background-image: linear-gradient(var(--static-Spa_Style_Gray2) 0%, var(--static-Spa_Style_Gray2) 0%, white 25%, white 100%);
  color: var(--static-Spa_Style_Black);
  font-size: var(--Spa_Style_Small_Font);
  box-sizing: border-box;
  width: 100%;
}

nextgen-form-renderer .sd-input[type='date'] {
  /* Making vertical alignment consistent in all browsers */
  line-height: var(--input-height);
  padding-top: 0;
  padding-bottom: 0;
}

nextgen-form-renderer .sd-remaining-character-counter {
  top: 0;
  bottom: 0;
  line-height: var(--input-height);
}

nextgen-form-renderer .sd-item--disabled.sd-item--disabled .sd-item__decorator,
nextgen-form-renderer .sd-item__decorator {
  border: 1px solid var(--spa-input-border-color);
}

nextgen-form-renderer .checkbox-indicator-wrapper,
nextgen-form-renderer .nextgen-sepreasons-checkbox {
  border: 1px solid var(--spa-input-border-color) !important;
}

nextgen-form-renderer div[data-name='PhysicianSearchLink'],
nextgen-form-renderer div[data-name='PhysicianSearchLink__autoGeneratedReviewElement'] {
  align-self: flex-end;
}

nextgen-form-renderer nextgen-provider-search .mdc-button,
nextgen-form-renderer .sd-html .mdc-button,
nextgen-form-renderer sv-ng-file-question .sd-action,
nextgen-form-renderer .sv-popup__body-footer .sd-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: var(--input-height) !important;
  line-height: 1.4 !important;
  font-size: var(--Spa_Style_Regular_Font) !important;
  font-size: 16px;
  font-family: montserrat_semibold;
  color: var(--Spa_Style_SecondaryButton_Text) !important;
  background-color: #dfdfdf !important;
  border-color: #adadad !important;
  margin-bottom: 0;
  height: auto !important;
  box-shadow: none;
  letter-spacing: normal;
}

nextgen-form-renderer .sd-html .mdc-button span {
  font-size: var(--Spa_Style_Regular_Font) !important;
  font-size: 16px;
  font-family: montserrat_semibold;
  line-height: inherit;
}

nextgen-form-renderer nextgen-provider-search .mdc-button:hover,
nextgen-form-renderer sv-ng-file-question .sd-action:hover,
nextgen-form-renderer .sv-popup__body-footer .sd-btn:hover {
  box-shadow: none !important;
  text-decoration: none;
  color: var(--Spa_Style_SecondaryButton_Text) !important;
  background-color: #c1c1c1 !important;
  border-color: #adadad !important;
}

nextgen-form-renderer sv-ng-file-question .sd-action .sv-svg-icon {
  fill: var(--Spa_Style_SecondaryButton_Text) !important;
  margin-right: 0.25rem !important;
  width: 20px;
  height: 20px;
}

nextgen-form-renderer .sv-popup__body-footer .sd-btn.sd-btn--danger {
  color: var(--Spa_Style_PrimaryButton_Text) !important;
  background-color: #fcb215 !important;
  border-color: #ca8000 !important;
}

nextgen-form-renderer .sv-popup__body-footer .sd-btn.sd-btn--danger:hover {
  background-color: #de9400 !important;
}

nextgen-form-renderer .sd-element--with-frame > .sd-element__erbox {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

nextgen-form-renderer .sd-error {
  background: none;
  padding: 0;
  padding-left: 21px;
  color: var(--static-Spa_Style_Accent2);
  font-family: var(--sjs-font-family);
  font-size: var(--Spa_Style_Small_Font);
  font-weight: 400;
}

nextgen-form-renderer .sd-error:before {
  content: '\f06a';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  width: 16px;
}

nextgen-form-renderer .sd-element--complex.sd-element--nested-with-borders > .sd-element__erbox,
nextgen-form-renderer .sd-element--complex.sd-element--with-frame > .sd-element__erbox {
  margin-left: 0;
  margin-right: 0;
  width: auto;
  margin-bottom: 8px;
}

nextgen-form-renderer .sd-question--error .sd-question__title {
  color: var(--static-Spa_Style_Accent2);
}

nextgen-form-renderer .sd-question--error .sd-input,
nextgen-form-renderer .sd-question--error .sd-dropdown {
  border-color: var(--static-Spa_Style_Accent2);
}

nextgen-form-renderer .sd-question__required-text,
nextgen-form-renderer .single-checkbox-content .req,
nextgen-form-renderer .mat-mdc-form-field-error,
nextgen-form-renderer .nextgen-sepreasons-required {
  color: var(--static-Spa_Style_Accent2) !important;
}

nextgen-form-renderer .sd-element[data-name='ApplicantPhoneMobile'] {
  padding-top: 0;
}

nextgen-form-renderer .sd-input.sd-input--disabled,
nextgen-form-renderer .sd-input.sd-input--disabled::placeholder,
nextgen-form-renderer .sd-element__title.sd-element__title--disabled {
  opacity: 0.5;
}

nextgen-form-renderer .sd-element--with-frame.sd-element--compact {
  border: none;
}

nextgen-form-renderer .sd-panel__header {
  border-bottom: 2px solid #cecece;
  border-radius: 0;
  padding: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))) !important;
  padding-right: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px))) !important;
}

nextgen-form-renderer .sd-element--expanded .sd-panel__header,
nextgen-form-renderer .sd-element--collapsed .sd-panel__header {
  padding: 0 !important;
}

nextgen-form-renderer .sd-element--expanded .sd-panel__header:hover,
nextgen-form-renderer .sd-element--collapsed .sd-panel__header:hover {
  background: rgba(0, 0, 0, 0.04);
}

nextgen-form-renderer .sd-element--expanded .sd-panel__header > h4,
nextgen-form-renderer .sd-element--collapsed .sd-panel__header > h4 {
  padding: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))) !important;
  padding-right: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px))) !important;
}

nextgen-form-renderer .sd-element--expanded .sd-panel__header > h4:focus,
nextgen-form-renderer .sd-element--collapsed .sd-panel__header > h4:focus {
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.04);
}

nextgen-form-renderer .sd-element--expanded .sd-element__title--expandable:before,
nextgen-form-renderer .sd-element--collapsed .sd-element__title--expandable:before {
  right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
  left: auto !important;
  top: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-image: none;
  height: var(--sjs-base-unit, var(--base-unit, 8px));
  width: var(--sjs-base-unit, var(--base-unit, 8px));
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  vertical-align: middle;
  transition: transform 0.2s;
}

nextgen-form-renderer .sd-element--expanded .sd-element__title--expandable.sd-element__title--expanded:before,
nextgen-form-renderer .sd-element--collapsed .sd-element__title--expandable.sd-element__title--expanded:before {
  transform: rotate(225deg) !important;
}

nextgen-form-renderer .sd-row:has(.script-content-empty) .sd-element--expanded,
nextgen-form-renderer .sd-row:has(.script-content-empty) .sd-element--collapsed {
  margin-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
}

nextgen-form-renderer .sd-element--collapsed > .sd-element__header {
  margin: 0;
  width: 100%;
  border-radius: 0 !important;
}

nextgen-form-renderer .sd-element--complex > .sd-element__header:after {
  display: none;
}

nextgen-form-renderer .sd-panel__header + .sd-panel__content {
  padding: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
}

nextgen-form-renderer .sd-panel__header .sd-element__title span {
  font-size: 16px;
}

nextgen-form-renderer .sd-page__row.sd-row--compact {
  margin-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
}

nextgen-form-renderer .sd-question__content input.sd-input:disabled:not(.sd-input--disabled) {
  opacity: 0.5;
}

nextgen-form-renderer .sd-item__control-label .sv-string-viewer {
  font-size: var(--spa-fontSize-small);
}

nextgen-form-renderer .single-checkbox-content .checkbox-label-wrapper {
  font-family: var(--sjs-font-family);
  font-size: var(--sjs-article-font-default-fontSize, var(--sjs-font-size, 16px));
}

nextgen-form-renderer .mat-expansion-panel-header-title {
  color: var(--Spa_Style_Primary);
  font-family: montserrat_bold, sans-serif;
  font-size: 16px;
  font-weight: var(--sjs-article-font-large-fontWeight, 500);
}

nextgen-form-renderer .mat-expansion-indicator:after {
  color: var(--Spa_Style_Primary);
}

nextgen-form-renderer .mat-expansion-panel-header-description {
  color: var(--Spa_Style_Gray4);
}

nextgen-form-renderer .nextgen-sepreasons-container .mat-expansion-panel-header .mat-content {
  gap: 0;
}

nextgen-form-renderer .sd-row {
  margin-top: calc(var(--sd-base-vertical-padding) * 0.5);
}

nextgen-form-renderer .sd-panel:not(.sd-panel--as-page) .sd-row--multiple {
  row-gap: calc(var(--sd-base-vertical-padding) * 0.5);
}

nextgen-form-renderer .nextgen-sepreasons-date-label {
  font-weight: var(--sjs-font-questiontitle-weight, 600);
  font-family: var(--sjs-font-questiontitle-family, var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family))));
  margin-bottom: 0;
}

nextgen-form-renderer input.obfuscate-text {
  /* Use -webkit-text-security if the browser supports it */
  -webkit-text-security: disc;
}

nextgen-form-renderer .toggle-obfuscation {
  cursor: pointer;
  display: inline-block;
  margin-left: -70px;
  width: 70px;
}

nextgen-form-renderer .toggle-obfuscation-text {
  text-decoration: underline;
  color: var(--Spa_Style_Primary_Link);
  justify-content: right;
  padding-right: 5px;
  width: 100%;
}

@-moz-document url-prefix() {
  /*For firefox browser, as -webkit-text-security not supported by FireFox*/
  nextgen-form-renderer input.obfuscate-text {
    font-family: text-security-disc;
    font-size: 1.5rem;
    padding-top: 10px !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /*For IE browser, as -webkit-text-security not supported by Internet Explorer*/
  nextgen-form-renderer input.obfuscate-text {
    font-family: text-security-disc;
    font-size: 1.2rem;
    padding-top: 12px !important;
  }
}

/* Tooltip */
nextgen-form-renderer .sd-question__title.nextgen-tooltip-container {
  display: flex;
  align-content: center;
  align-items: center;
}

/* Disabled fields */
nextgen-form-renderer .sd-input.sd-input--disabled,
nextgen-form-renderer .sd-input.sd-input--disabled::placeholder,
nextgen-form-renderer .sd-element__title.sd-element__title--disabled,
nextgen-form-renderer .sd-question__content input.sd-input:disabled:not(.sd-input--disabled) {
  opacity: 1;
  border-color: var(--spa-disabled-input-border-color);
  color: var(--spa-disabled-input-color);
}

nextgen-form-renderer .sd-input.sd-input--disabled,
nextgen-form-renderer .sd-question__content input.sd-input:disabled:not(.sd-input--disabled) {
  background: var(--spa-disabled-input-background);
  cursor: not-allowed;
}

nextgen-form-renderer .sd-element__title.sd-element__title--disabled {
  color: var(--spa-disabled-input-color);
}

nextgen-form-renderer .sd-question--disabled .sv-dropdown_select-wrapper,
nextgen-form-renderer .sd-question--disabled .sd-selectbase__label,
nextgen-form-renderer .sep-reasons-read-only > label,
nextgen-form-renderer .sd-question--disabled .sd-boolean-root,
nextgen-form-renderer .sd-question--disabled .single-checkbox-content,
nextgen-form-renderer .sd-question--disabled .sd-signaturepad {
  cursor: not-allowed;
}

nextgen-form-renderer .sd-question--disabled .single-checkbox-content .checkbox-label-wrapper {
  color: var(--spa-disabled-input-color) !important;
}

nextgen-form-renderer .sd-question--disabled input[custom-checkbox] + .checkbox-checked {
  background-color: var(
    --sjs-editorpanel-backcolor,
    var(--sjs-editor-background, var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9)))
  ) !important;
}

nextgen-form-renderer .sd-question--disabled .checkbox-indicator {
  border-color: var(--sjs-border-default, var(--border, #d6d6d6)) !important;
}

nextgen-form-renderer .sd-item--disabled .sd-item__control-label {
  color: var(--spa-disabled-input-color);
  opacity: 1;
}

nextgen-form-renderer .sd-item--disabled.sd-item--disabled .sd-item__decorator {
  background: var(--spa-disabled-input-background);
  border-color: var(--spa-disabled-input-border-color);
}

nextgen-form-renderer .sep-reasons-read-only {
  opacity: 1;
}

nextgen-form-renderer .sep-reasons-read-only .nextgen-sepreasons-checkbox {
  background: var(--spa-disabled-input-background);
  border-color: var(--spa-disabled-input-border-color) !important;
}

nextgen-form-renderer .sep-reasons-read-only .nextgen-tooltip-container {
  color: var(--spa-disabled-input-color);
}

nextgen-form-renderer .sep-reasons-read-only .nextgen-sepreasons-date-label {
  color: var(--spa-disabled-input-color);
}

nextgen-form-renderer .sd-boolean--disabled .sd-boolean__thumb {
  opacity: 0.5;
}

nextgen-form-renderer .sd-boolean--disabled .sd-boolean__thumb-ghost span {
  color: var(--spa-disabled-input-color) !important;
  opacity: 1;
}

nextgen-form-renderer .sd-boolean--disabled .sd-boolean__thumb-ghost:has(.sd-boolean__label--true) {
  left: -2px;
  position: relative;
}

nextgen-form-renderer .sd-boolean--disabled .sd-boolean__thumb-ghost:has(.sd-boolean__label--false) {
  left: 2px;
  position: relative;
}

nextgen-form-renderer .nextgen-sepreasons-container .mat-accordion .mat-expansion-panel {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sat-popover-container {
  opacity: 0;
  transform: scale(1) !important;
}

.sat-popover-container:has(.tooltip-content.tooltip-arrow) {
  opacity: 1 !important;
}

.sat-popover-container .tooltip-container {
  color: var(--static-Spa_Style_Primary_Darker);
  background-color: #ffffff !important;
  box-shadow: 0px 0px 8px 3px var(--static-Spa_Style_Gray4);
  text-align: left;
  border: 1px solid var(--static-Spa_Style_Accent1);
  font-family: var(--sjs-font-family);
  font-size: var(--Spa_Style_Small_Font) !important;
  min-width: 180px;
  max-width: 220px;
  padding: 10px;
  border-radius: 6px;
}

.sat-popover-container.sat-popover-before {
  transform: translateX(8px) !important;
}

.sat-popover-container.sat-popover-after {
  transform: translateX(-8px) !important;
}

.sat-popover-container .tooltip-container.tooltip-arrow:after {
  left: var(--sat-tooltip-arrow-left-offset);
}

.sat-popover-container.sat-popover-above {
  margin-bottom: 12px !important;
}

.sat-popover-container.sat-popover-above .tooltip-arrow:after {
  content: var(--sat-tooltip-down-pointing-arrow);
  bottom: -14px;
}

.sat-popover-container.sat-popover-below {
  margin-top: 12px !important;
}

.sat-popover-container.sat-popover-below .tooltip-arrow:after {
  content: var(--sat-tooltip-up-pointing-arrow);
  top: -13px;
}

.sat-popover-container .tooltip-content h3,
.sat-popover-container .tooltip-content h4,
.sat-popover-container .tooltip-content h5 {
  font-weight: 600;
}

.sat-popover-container .tooltip-content h3 {
  font-size: var(--Spa_Style_Large_Font) !important;
}

.sat-popover-container .tooltip-content h4 {
  font-size: var(--Spa_Style_Regular_Font) !important;
}

.sat-popover-container .tooltip-content h5 {
  font-size: var(--Spa_Style_Small_Font) !important;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sat-popover-container .tooltip-container.tooltip-container-wide {
    max-width: 400px;
  }
}

@media (min-width: 768px) {
  .sat-popover-container .tooltip-container.tooltip-container-wide {
    max-width: 500px;
  }
}
